// Projects.js
import React, { useState, useEffect } from "react";
import InternshipCarousel from "./projects/InternshipCarousel";
import { experienceData } from "../constants/experienceData";
import ClassesSection from "./projects/Classes";
import VideoGrid from "./projects/VideoGrid";
import "../App.css";

export default function Projects() {
  const [hoveredClass, setHoveredClass] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date().toISOString().split('T')[0]);
    }, 60000);
    
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="projectPage">
      {/* <div className="modal-overlay">
        <div className="modal-content">
          <h2>Under Construction</h2>
          <p>
            This section is currently in the works as of {currentDate}.
            Please check back later!
          </p>
        </div>
      </div> */}
      <h2>My Experiences</h2>
      <p>Explore my internships</p>
      <InternshipCarousel data={experienceData} />

      <h2>My Projects</h2>
      <p>Hover over a project to see more!</p>
      <VideoGrid />

      <h2>My Classwork</h2>
      <p>This class is: {hoveredClass || "Hover over something!"}</p>
      <ClassesSection onHoverClass={setHoveredClass} />
    </div>
  );
}


