import React from "react";
import "../../App.css";

// A reusable component for each class box that handles hover events.
function HoverClassBox({ className, children, onHoverClass, fullName }) {
  return (
    <div
      className={`class-box ${className}`}
      onMouseEnter={() => onHoverClass(fullName)}
      onMouseLeave={() => onHoverClass("")}
    >
      {children}
    </div>
  );
}

export default function ClassesSection({ onHoverClass }) {
  return (
    <div className="classes-container">
      <div className="semester-row swipe-container">
        {/* Fall 2022 */}
        <div className="semester-column">
          <h3>Fall 2022</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Introduction to C++ Programming"
          >
            COMPSCI 110
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Analytic Geometry/Calculus II"
          >
            MATH 193
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="First-Year College Composition and Reading"
          >
            ENGL 122
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="American Cinema/American Culture"
          >
            FTVE 200
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Humanities: The Roots of Hell"
          >
            HUMAN 108
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Introduction to Psychology"
          >
            PSYCH 101
          </HoverClassBox>
        </div>

        {/* Spring 2023 */}
        <div className="semester-column">
          <h3>Spring 2023</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Advanced Programming with C++"
          >
            COMPSCI 165
          </HoverClassBox>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Structure and Interpretation of Computer Programs"
          >
            COMPSCI 61
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Mathematical Tutor Training"
          >
            MATH 140
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Analytic Geometry and Calculus III"
          >
            MATH 292
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Fundamentals of Biology"
          >
            BIOSC 101
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Intro to Social Justice"
          >
            SOCSC 101
          </HoverClassBox>
        </div>

        {/* Summer 2023 */}
        <div className="semester-column">
          <h3>Summer 2023</h3>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Linear Algebra"
          >
            MATH 194
          </HoverClassBox>
          <HoverClassBox
            className="phys"
            onHoverClass={onHoverClass}
            fullName="Mechanics and Wave Motion"
          >
            PHYS 130
          </HoverClassBox>
        </div>

        {/* Fall 2023 */}
        <div className="semester-column">
          <h3>Fall 2023</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Program Design & Data Structures"
          >
            COMPSCI 210
          </HoverClassBox>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Assembly Language Programming"
          >
            COMPSCI 260
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Differential Equations"
          >
            MATH 294
          </HoverClassBox>
          <HoverClassBox
            className="phys"
            onHoverClass={onHoverClass}
            fullName="Heat and Electro-Magnetism"
          >
            PHYS 230
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Critical Thinking: Composition and Literature"
          >
            ENGL 123
          </HoverClassBox>
        </div>

        {/* Spring 2024 */}
        <div className="semester-column">
          <h3>Spring 2024</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Object Oriented Programming"
          >
            COMPSCI 200
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Discrete Mathematics"
          >
            MATH 195
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Intro to Upper Division Math"
          >
            MATH 289
          </HoverClassBox>
          <HoverClassBox
            className="ee"
            onHoverClass={onHoverClass}
            fullName="Intro to Circuits & Devices"
          >
            EE 230
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Psychology in Modern Life"
          >
            PSYCH 122
          </HoverClassBox>
        </div>

        {/* Fall 2024 */}
        <div className="semester-column">
          <h3>Fall 2024</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Discrete Mathematics and Probability Theory"
          >
            COMPSCI 70
          </HoverClassBox>
          <HoverClassBox
            className="ee"
            onHoverClass={onHoverClass}
            fullName="Designing Information Devices and Systems I"
          >
            EE 16A
          </HoverClassBox>
          <HoverClassBox
            className="phys"
            onHoverClass={onHoverClass}
            fullName="Berkeley Connect in Physics"
          >
            PHYS 189BC
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Ethics, Engineering, and Society"
          >
            ENGIN 125
          </HoverClassBox>
        </div>

        {/* Spring 2025 */}
        <div className="semester-column">
          <h3>Spring 2025</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Computer Security"
          >
            COMPSCI 161
          </HoverClassBox>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Introduction to the Internet: Architecture and Protocols"
          >
            COMPSCI 168
          </HoverClassBox>
          <HoverClassBox
            className="ee"
            onHoverClass={onHoverClass}
            fullName="Intro to Circuits & Devices"
          >
            EE 16B
          </HoverClassBox>
        </div>

        {/* Fall 2025 */}
        <div className="semester-column">
          <h3>Fall 2025</h3>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Cryptography"
          >
            CS 171
          </HoverClassBox>
          <HoverClassBox
            className="cs"
            onHoverClass={onHoverClass}
            fullName="Introduction to Database Systems"
          >
            CS 186
          </HoverClassBox>
          <HoverClassBox
            className="graduate"
            onHoverClass={onHoverClass}
            fullName="Public Interest Cybersecurity"
          >
            INFO 289
          </HoverClassBox>
          <HoverClassBox
            className="math"
            onHoverClass={onHoverClass}
            fullName="Introduction to Poker Theory"
          >
            Stat 198
          </HoverClassBox>
          <HoverClassBox
            className="breadth"
            onHoverClass={onHoverClass}
            fullName="Special Topics in US History"
          >
            History 100AC
          </HoverClassBox>
        </div>
      </div>

      <div className="classes-legend">
        <div className="legend-item">
          <div className="legend-color breadth" />
          <span>Breadth</span>
        </div>
        <div className="legend-item">
          <div className="legend-color cs" />
          <span>CS</span>
        </div>
        <div className="legend-item">
          <div className="legend-color ee" />
          <span>EE</span>
        </div>
        <div className="legend-item">
          <div className="legend-color phys" />
          <span>Physics</span>
        </div>
        <div className="legend-item">
          <div className="legend-color math" />
          <span>Math</span>
        </div>
        <div className="legend-item">
          <div className="legend-color graduate" />
          <span>Graduate</span>
        </div>
      </div>
    </div>
  );
}