import ncsa from "../assets/imgs/projects/ncsa.jpeg";
import seti from "../assets/imgs/projects/seti.jpg";
import LBNL from "../assets/imgs/projects/LBNL.jpg";

export const experienceData = [
    {
        name: "Berkeley Lab",
        status: "Incoming",
        image: LBNL,
        link: "https://www.lbl.gov/",
        title: "IT Operations Intern",
        details: {
            desc: "April - July 2025",
            more: "Berkeley, CA"
        },
        back: {
            tech: "Strengthened cloud security by auditing compliance reports, and vulnerable servers",
          },
          tags: ["Forensics", "Networking", "Monitoring"] 
        },
  {
    name: "NCSA Lab",
    image: ncsa,
    status: "Current",
    link: "https://www.ncsa.illinois.edu/",
    title: "Security Analyst Intern",
    details: {
      desc: "December 2024 - May 2025",
      more: "Oakland, CA"
    },
    back: {
      tech: "Strengthened cloud security by auditing compliance reports, and vulnerable servers",
    },
    tags: ["Compliance", "Cloud", "Auditing"] 
  },
  {
    name: "NCSA Lab",
    image: ncsa,
    status: "Completed",
    link: "https://www.ncsa.illinois.edu/",
    title: "Security Engineering Intern",
    details: {
      desc: "June - December 2024",
      more: "Urbana, IL"
    },
    back: {
      tech: "Developed an internal tool for SSH key revocation and automatic key revocation ",
    },
    tags: ["Security", "Full-Stack", "Internal Tool"] 
  },
  {
    name: "SETI Institute",
    image: seti,
    status: "Completed",
    link: "https://www.seti.org/",
    title: "Computation Intern",
    details: {
      desc: "January - May 2024",
      more: "Mountain View, CA"
    },
    back: {
      tech: "Optimized planetary formation modeling for high-fidelity protoplanetary disk simulations.",
    },
    tags: ["Computation", "Planetary", "Simulation"] 
  },
];
