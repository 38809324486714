import video1 from "../assets/imgs/projects/video.mp4";
import video2 from "../assets/imgs/projects/video2.mp4";
import video3 from "../assets/imgs/projects/video3.mp4";
import video4 from "../assets/imgs/projects/video4.mp4";
import video5 from "../assets/imgs/projects/video5.mp4";
import video6 from "../assets/imgs/projects/video6.mp4";


const videos = [
  {
    id: 1,
    src: video1,
    title: "Compromise Vault",
    description: "Internal Tool for SSH Key Revocation",
  },
  {
    id: 2,
    src: video2,
    title: "AstroWind",
    description: "Stellar Wind Density Modeler",
  },
  {
    id: 3,
    src: video3,
    title: "Worldview Wanderer",
    description: "Tourist Powerpoint Recreation",
    
  },
  {
    id: 4,
    src: video4,
    title: "Traceroute",
    description: "Graphical Traceroute implementation",
  },
  {
    id: 5,
    src: video5,
    title: "VReader",
    description: "Roblox VR Book Reader",
  },
  {
    id: 6,
    src: video6,
    title: "Distance Vector Routing ",
    description: "Network Routing Algorithm Visualization",
  },
];

export default videos;
