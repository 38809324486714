
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from './Homepage';
import Projects from './Projects';
import Resume from './Resume'; 
import React from 'react';
import "../App.css";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <Routes key={location.pathname} location={location}>
      <Route exact path="/" element={<Homepage />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/resume" element={<Resume />} />
    </Routes>
  );
}

export default AnimatedRoutes;