// App.js
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from './components/headers/Navbar';
import AnimatedRoutes from "./components/AnimatedRoutes";
import Footer from './components/headers/Footer';
import './App.css';
  
export const App = () => {
  return (
    <div className="App" data-theme="dark">
      <Router>
        <Navbar />
        <AnimatedRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
