import React from "react";
import "../../App.css";
import videos from "../../constants/videoData";



const VideoGrid = () => {
  return (
    <div className="video-grid-container">
      {videos.map((video) => (
        <div className="video-item" key={video.id}>
          <video
            src={video.src}
            autoPlay
            muted
            loop
            playsInline
          >
            Your browser does not support the video tag.
          </video>
          <div className="video-overlay">
            <h3>{video.title}</h3>
            <p>{video.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;
