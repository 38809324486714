import React, { useState, useEffect } from "react";
import "../../App.css";

export default function InternshipCarousel({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const autoRotateInterval = 5000; // 5 seconds

  // Automatic rotation
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, autoRotateInterval);

    return () => clearInterval(timer);
  }, [data.length]);

  // Manual navigation
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  const currentItem = data[currentIndex];

  return (
    <div className="internship-carousel">
      <div className="carousel-container">
        <button className="carousel-button prev" onClick={goToPrevious}>
          ←
        </button>

        <div className="carousel-content">
          <div className="internship-card">
            <div className="internship-image-container">
              <img
                src={currentItem.image}
                alt={currentItem.name}
                className="internship-image"
              />
              <div className="image-caption">
                <p>{currentItem.status} Internship</p>
              </div> 
            </div>

            <div className="internship-details">
              <h3 className="internship-title">{currentItem.name}</h3>
              <h4 className="internship-subtitle">{currentItem.title}</h4>
              <p className="internship-description">
                {currentItem.details.desc} | {currentItem.details.more}
              </p>
              <p className="internship-tech">{currentItem.back.tech}</p>
              <div className="internship-tags">
                {(currentItem.tags || []).map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
              <div className="internship-actions">
                <a
                  href={currentItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="internship-link"
                >
                  Visit Organization
                </a>
                <button className="inline-next-button" onClick={goToNext}>
                  Next →
                </button>
              </div>
            </div>
          </div>

          <div className="carousel-dots">
            {data.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </div>

        <button className="carousel-button next" onClick={goToNext}>
          →
        </button>
      </div>
    </div>
  );
}