import React, { useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import LMGithub from "../assets/imgs/icons/github.png";
import LMMail from "../assets/imgs/icons/mail.png";
import LMLinkedIn from "../assets/imgs/icons/linkedin.png";
import cyberIcon from "../assets/imgs/homepage/1.jpg"; 
import archeryIcon from "../assets/imgs/homepage/2.jpg";
import musicIcon from "../assets/imgs/homepage/3.jpg";
import codeIcon from "../assets/imgs/homepage/4.jpg";
import berkeleyIcon from "../assets/imgs/homepage/5.jpg";
import guitarIcon from "../assets/imgs/homepage/6.jpg";

const Homepage = () => {  
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    
    const randomAngle = () => Math.floor(Math.random() * 360);
    setRotation({ x: randomAngle(), y: randomAngle() });
  }, []); 

  const socials = [
    {
      href: "https://www.github.com/k1zum1",
      alt: "Github",
      src: LMGithub
    },
    {
      href: "https://www.linkedin.com/in/kelly-tran-2210/",
      alt: "LinkedIn",
      src: LMLinkedIn
    },
    {
      href: "mailto:jaychong@berkeley.edu",
      alt: "Mail",
      src: LMMail
    },
  ];

  return (
    <div className="homePage">
      <div className="heroContainer">
        <div className="heroText">
          <h3>hi there! i'm</h3>
          <h1>jay chong</h1>
          <h4>i do <strong>cybersecurity</strong> stuff and shoot collegiate <strong>compound</strong> archery on the side. thanks for visiting.</h4>
          <div className="buttonContainer">
            <Link className="homeButtons" to="/resume">
              resume <p> → </p>
            </Link>
            <Link className="homeButtons" to="/projects">
              what i do <p> → </p>
            </Link>
          </div>
        </div>
        <div className="cubeContainer">
          <div className="cube" style={{ transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)` }}>
            <div className="face front"><img src={cyberIcon} alt="Cybersecurity" /></div>
            <div className="face back"><img src={archeryIcon} alt="Archery" /></div>
            <div className="face right"><img src={musicIcon} alt="Music" /></div>
            <div className="face left"><img src={codeIcon} alt="Coding" /></div>
            <div className="face top"><img src={berkeleyIcon} alt="UC Berkeley" /></div>
            <div className="face bottom"><img src={guitarIcon} alt="Guitar" /></div>
          </div>
        </div>
      </div>
      <div className="musicContainer">
        <div className="spotifyContainer">
          <h2> favorite song: </h2>
          <iframe 
            style={{ borderRadius: '50px' }} 
            title="Spotify Playlist" 
            src="https://open.spotify.com/embed/track/0pYacDCZuRhcrwGUA5nTBe?utm_source=generator" 
            width="100%" 
            height="352" 
            frameBorder="0" 
            allowFullScreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
          ></iframe>
        </div>
        <div className="latestInfo">
          <h2> currently: </h2>
          <h3>junior @ UC Berkeley B.S. electrical engineering & computer science</h3>
          <h2> working: </h2>
          <h3>cyber analyst intern @ NCSA</h3>
          <h2>what's next </h2> 
          <h3>intern @ lbnl</h3>
          <h2> listening to: </h2>
          <h3>a lot of dlwlrma</h3>
        </div>
      </div>
    </div>
  );
};

export default Homepage;