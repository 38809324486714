// Resume.js
import React from 'react';
import '../App.css';


import resumePDF from '../assets/imgs/Jay_Chong_Website_Resume.pdf'

const Resume = () => {
  return (
    <div className="resumePage">
      <div className="resumeContainer">
        <h2>Resume Preview</h2>
        <div className="resumePreview">

          <embed
            src={resumePDF}
            type="application/pdf"
            width="100%"
            height="800px"
            style={{ borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.25)' }}
          />
          <div className="mobileFallback">
            <p>PDF preview is not supported on this device. Please download the resume below.</p>
          </div>
        </div>
        <div className="downloadButtonContainer">
          <a
            href={resumePDF}
            download="Jay_Chong_Website_Resume.pdf"
            className="homeButtons resumeDownloadButton"
          >
            Download Resume <p> ↓ </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resume;